import '/src/css/app.css';
import Alpine from 'alpinejs'
import collapse from '@alpinejs/collapse'

// ckeditor/ckeditor5-editor-classic
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


Alpine.plugin(collapse)
window.Alpine = Alpine;
Alpine.start();


const editors = document.querySelectorAll('.js-ckeditor');

editors.forEach(editor => {
    ClassicEditor.create(editor, {
        licenseKey: 'GPL', // Or 'GPL'.
        toolbar: {
            items: [
                'heading',
                '|',
                'bold',
                'italic',
                'link',
            ]
        },
    })
    .then(editor => {
        console.log(editor);
    })
    .catch(error => {
        console.error(error);
    });
});
